<template>
  <div>
    <AdminAlertForm
      :alert="alert"
      @submit="addAlert"
      :title="'Add New Park Alert'"
    />
  </div>
</template>

<script>
import AdminAlertService from "@/services/admin/AdminAlertService.js";
import AdminAlertForm from "@/components/admin/AdminAlertForm";

export default {
  name: "AdminAlertAdd",
  title: "Admin - Add New Park Alert",
  components: {
    AdminAlertForm
  },
  data() {
    return {
      alert: {
        startDate: "",
        endDate: "",
        globalAlert: false,
        message: "",
        applyToEmail: false,
        applyToWebsite: false
      }
    };
  },
  methods: {
    addAlert(request) {
      let alertService = new AdminAlertService(this.tenantId);
      alertService?.addAlert(request).then(response => {
        if (response.statusCode == "Success") {
          this.$router.push("/admin/park-alert-search").catch(() => {});
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Park Alert Added Successfully",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  }
};
</script>
